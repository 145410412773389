/** @jsxImportSource @emotion/react */

import { useTheme } from "@mui/material";

interface BadgeBodyTileProps {
  child?: React.ReactNode;
}
export const BadgeBodyTile = ({ child }: BadgeBodyTileProps): JSX.Element => {
  const theme = useTheme();
  return (
    <div
      className="BadyBodyTile"
      css={{
        padding: "0.70rem 1rem",
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: "1rem",
        flexGrow: 1,
        minWidth: "141.5px",
        maxWidth: "141.5px",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {child}
      </div>
    </div>
  );
};
