/** @jsxImportSource @emotion/react */

import { Grid, Typography, useTheme } from "@mui/material";
import { Currency, Position, Token } from "../../../gql/types";
import {
  applyColorByValue,
  currencyToCurrencySymbol,
  formatFiatCurrency,
} from "../../../utils";

import arrowDown from "../../../images/arrowDown.svg";
import arrowUp from "../../../images/arrowUp.svg";

interface badgeHeaderProps {
  openPosition: Position;
  token: Token;
}

export const BadgeHeader = ({
  openPosition,
  token,
}: badgeHeaderProps): JSX.Element => {
  const theme = useTheme();
  const currencySymbol = currencyToCurrencySymbol(
    Currency[openPosition.fees.fiatCurrency]
  );
  const locale = currencySymbol === "$" ? "en-US" : "de-DE";

  return (
    <Grid
      container
      className="CardHeader"
      css={{
        maxHeight: "114px",
        padding: "1.25rem",
        width: "inherit",
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
      }}
    >
      <Grid item md={9}>
        <Typography
          variant="label3"
          css={{ color: theme.palette.grey[100], marginTop: "5rem" }}
        >
          {token.symbol?.toUpperCase()}
        </Typography>
        <Typography variant="h2">My Position</Typography>
        <div
          css={{
            display: "flex",
          }}
        >
          <img
            src={
              parseFloat(openPosition.unrealizedPnlAbs) >= 0
                ? arrowUp
                : arrowDown
            }
            alt={"Arrow up green"}
            css={{
              maxWidth: "1rem",
              minWidth: "1rem",
              minHeight: "auto",
              objectFit: "contain",
            }}
          />
          <Typography
            variant="h3"
            css={{
              paddingLeft: "0.125rem",
              paddingTop: "0.075rem",
            }}
            color={applyColorByValue(openPosition.unrealizedPnlAbs)}
          >
            {formatFiatCurrency(
              openPosition.fees.fiatCurrency,
              openPosition.unrealizedPnlAbs,
              locale
            )}
          </Typography>
          <Typography
            variant="label2"
            css={{
              paddingLeft: "0.375rem",
            }}
            display="flex"
            alignItems="center"
            color={theme.palette.grey[100]}
          >
            P&L - Open
          </Typography>
        </div>
      </Grid>
      <Grid item md={3} textAlign="center">
        {token.logoUri && (
          <img
            src={token.logoUri}
            alt={token.name}
            css={{
              position: "relative",
              top: "52%",
              transform: "translateY(-48%) translateX(5%)",
              maxWidth: "3rem",
              minWidth: "3rem",
              height: "auto",
              borderRadius: "50%",
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};
