/** @jsxImportSource @emotion/react */

import { BadgeBodyTile } from "./BadgeBodyTile";

interface BadyBodyRowProps {
  childLeft?: React.ReactNode;
  childRight?: React.ReactNode;
}
export const BadgeBodyRow = ({
  childLeft,
  childRight,
}: BadyBodyRowProps): JSX.Element => {
  return (
    <div
      className="BadeBodyRow"
      css={{
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
      }}
    >
      <BadgeBodyTile child={childLeft} />
      <BadgeBodyTile child={childRight} />
    </div>
  );
};
