import { PaletteOptions, createTheme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

const fontDefinitions: TypographyOptions = {
  h1: {
    fontWeight: 700,
    fontSize: "3.3125rem",
    fontStyle: "normal",
    lineHeight: "56px",
    textTransform: "uppercase",
  },
  // title2
  h2: {
    // 26px
    fontSize: "1.625rem",
    fontWeight: 700,
    lineHeight: "30px",
  },
  //title3
  h3: {
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "20px",
  },
  //title4
  h4: {
    fontWeight: 700,
  },
  //body1
  body1: {
    fontWeight: 400,
  },
  //body2
  body2: {
    // 15px
    fontSize: "0.9375rem",
    fontWeight: 400,
    lineHeight: "21px",
  },
  body3: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "18px",
  },
  label1: {
    // 17px
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "28px",
  },
  label2: {
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "22px",
  },
  label3: {
    fontWeight: 500,
    // fontSize: "13px",
    fontSize: "0.8125rem",
    lineHeight: "18px",
  },
};

const typographyPrimary: TypographyOptions = {
  fontFamily: ["Universal Sans Text"].join(","),
  // fontSize: 16,
  ...fontDefinitions,
};

const typographyLazarGrotesk: TypographyOptions = {
  fontFamily: ["Lazare Grotesk"].join(","),
  // fontSize: 16,
  ...fontDefinitions,
};

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    label1: React.CSSProperties;
    label2: React.CSSProperties;
    label3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    label1?: React.CSSProperties;
    label2?: React.CSSProperties;
    label3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    label1: true;
    label2: true;
    label3: true;
  }
}

const sharedColorPallet: PaletteOptions = {
  error: {
    main: "#FC6060",
  },
  warning: {
    main: "#FC983A",
  },
  success: {
    main: "#1AC989",
  },
  info: {
    main: "#0083FD",
  },
};

const lightThemeColorPallet: PaletteOptions = {
  ...sharedColorPallet,
  grey: {
    50: "#000000",
    // Light/Neutral/Neutral1
    100: "#888C94",
    // dark utility 1
    200: "rgba(255, 255, 255, 0.08)",
    300: "#D1D3D6",
    400: "#E5E7EA",
    500: "#F2F5F7",
    600: "#F9FAFB",
  },
  background: {
    default: "#F9FAFB",
    paper: "#F9FAFB",
  },
  text: {
    primary: "#000000",
    secondary: "#76797A",
    disabled: "#AEB0B2",
  },
};

// TODO: Dark Mode needs work
const darkThemeColorPallet: PaletteOptions = {
  ...sharedColorPallet,
  grey: {
    50: "#000000",
    // Light/Neutral/Neutral1
    100: "#888C94",
    // dark utility 1
    200: "rgba(255, 255, 255, 0.08)",
    300: "#17171A",
    400: "#E5E7EA",
    500: "#F2F5F7",
    600: "#F9FAFB",
  },
  background: {
    // base1
    default: "#09090A",
    // base2
    paper: "#17171A",
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#9FAAAB",
    disabled: "#465052",
  },
};
export const lightTheme = createTheme({
  typography: typographyPrimary,
  palette: {
    ...lightThemeColorPallet,
  },
});
export const lightThemeLazarGrotesk = createTheme({
  typography: typographyLazarGrotesk,
  palette: {
    ...lightThemeColorPallet,
  },
});

export const darkTheme = createTheme({
  typography: typographyPrimary,
  palette: {
    ...darkThemeColorPallet,
  },
});

export const darkThemeLazarGrotesk = createTheme({
  typography: typographyLazarGrotesk,
  palette: {
    ...darkThemeColorPallet,
  },
});
