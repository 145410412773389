/** @jsxImportSource @emotion/react */

import { useLazyQuery } from "@apollo/client";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { useEffect } from "react";
import { GET_POSITION } from "./gql/getPositions";
import { TokenPositionData } from "./gql/types";
import { LoadingPage } from "./Pages/LoadingPage";
import { PositionPage } from "./Pages/PositionPage";
import { darkTheme } from "./styles/theme";
interface AppProps {
  uri?: string;
}

export const App = ({ uri }: AppProps): JSX.Element => {
  const [getTokenPosition, { loading, data }] = useLazyQuery<TokenPositionData>(
    GET_POSITION,
    {
      variables: {
        uri,
      },
    }
  );

  useEffect(() => {
    if (uri && !data) {
      getTokenPosition();
    }
  }, [data, getTokenPosition, uri]);
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

      <div
        className="App"
        css={{
          width: "1005px",
          height: "526px",
          backgroundColor: darkTheme.palette.background.default,
          borderRadius: "20px",
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
        }}
      >
        {loading && <LoadingPage />}
        {!loading && data?.tokenPosition.openPosition && (
          <PositionPage
            openPosition={data.tokenPosition.openPosition}
            token={data.tokenPosition.token}
          />
        )}
      </div>
    </ThemeProvider>
  );
};
