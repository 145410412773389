/** @jsxImportSource @emotion/react */

import { Typography, useTheme } from "@mui/material";
import { Currency, Position, Token } from "../../gql/types";
import {
  applyColorByValue,
  formatFiatCurrency,
  scaleToPercentage,
  currencyToCurrencySymbol,
} from "../../utils";

import { BadgeHeader } from "./PositionBadge/BadgeHeader";
import { BadgeBodyRow } from "./PositionBadge/BadgeBodyRow";
import { Fragment } from "react";
import { cryptoFormat } from "../../formatting";

interface PositionBadgeProps {
  openPosition: Position;
  token: Token;
}

export const PositionBadge = ({
  openPosition,
  token,
}: PositionBadgeProps): JSX.Element => {
  const theme = useTheme();
  const currencySymbol = currencyToCurrencySymbol(
    Currency[openPosition.fees.fiatCurrency]
  );
  const locale = currencySymbol === "$" ? "en-US" : "de-DE";
  const isPositive = Number(openPosition.unrealizedPnlAbs) > 0;
  return (
    <div
      css={{
        marginLeft: "-10px",
        width: "335px",
        background: theme.palette.background.paper,
        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.16)",
        borderRadius: "1rem",
      }}
      className="PositionCard"
    >
      <BadgeHeader openPosition={openPosition} token={token} />
      <div
        className="BadgeBody"
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "1.25rem",
          gap: "0.75rem",
        }}
      >
        <BadgeBodyRow
          childLeft={
            <Fragment>
              <Typography
                variant="label2"
                css={{ color: theme.palette.grey[100] }}
              >
                Size
              </Typography>
              <Typography variant="label1" css={{ whiteSpace: "nowrap" }}>
                {cryptoFormat(openPosition.size, token.symbol, locale, [2, 5])}{" "}
              </Typography>
            </Fragment>
          }
          childRight={
            <Fragment>
              <Typography
                variant="label2"
                css={{ color: theme.palette.grey[100] }}
              >
                Value
              </Typography>
              <Typography variant="label1">
                {formatFiatCurrency(
                  Currency[openPosition.fees.fiatCurrency],
                  openPosition.valueOfOpen,
                  locale
                )}
              </Typography>
            </Fragment>
          }
        />
        <BadgeBodyRow
          childLeft={
            <Fragment>
              <Typography
                variant="label2"
                css={{ color: theme.palette.grey[100] }}
              >
                Entry Price
              </Typography>
              <Typography variant="label1">
                {formatFiatCurrency(
                  Currency[openPosition.fees.fiatCurrency],
                  openPosition.averagePriceOpen,
                  locale
                )}
              </Typography>
            </Fragment>
          }
          childRight={
            <Fragment>
              <Typography
                variant="label2"
                css={{ color: theme.palette.grey[100] }}
              >
                Current Price
              </Typography>
              <Typography variant="label1">
                {formatFiatCurrency(
                  Currency[openPosition.fees.fiatCurrency],
                  openPosition.currentPrice,
                  locale
                )}
              </Typography>
            </Fragment>
          }
        />

        <BadgeBodyRow
          childLeft={
            <Fragment>
              <Typography
                variant="label2"
                css={{ color: theme.palette.grey[100] }}
              >
                {isPositive ? `Profit ` : `Loss `} (
                {currencyToCurrencySymbol(
                  Currency[openPosition.fees.fiatCurrency]
                )}
                )
              </Typography>
              <Typography
                variant="label1"
                color={applyColorByValue(openPosition.unrealizedPnlAbs)}
              >
                {isPositive ? "+" : ""}
                {formatFiatCurrency(
                  Currency[openPosition.fees.fiatCurrency],
                  openPosition.unrealizedPnlAbs,
                  locale
                )}
              </Typography>
            </Fragment>
          }
          childRight={
            <Fragment>
              <Typography
                variant="label2"
                css={{ color: theme.palette.grey[100], whiteSpace: "nowrap" }}
              >
                {isPositive ? "Profit (%)" : "Loss (%)"}{" "}
              </Typography>
              <Typography
                variant="label1"
                color={applyColorByValue(openPosition.unrealizedPnl)}
              >
                {isPositive ? "+" : ""}
                {scaleToPercentage(Number(openPosition.unrealizedPnl), 2)}%
              </Typography>
            </Fragment>
          }
        />
      </div>
    </div>
  );
};
