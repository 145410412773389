import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const path = window.location.href.split("?");

const targetUrl = () => {
  if (path.length === 3) {
    let env = path[1];
    return `https://api.${env}.unstp.io/graphql`;
  } else {
    return "https://api.ultimate.money/graphql";
  }
};

const targetUri = (): string | undefined => {
  if (path.length === 3) {
    return path[2];
  } else if (path.length === 2) {
    return path[1];
  } else {
    return undefined;
  }
};

const client = (uri: string) =>
  new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });

root.render(
  <ApolloProvider client={client(targetUrl())}>
    <App uri={targetUri()} />
  </ApolloProvider>
);
