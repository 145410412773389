import { Currency, TokenPosition } from "./gql/types";

export function applyColorByValue(amount: string | undefined): string {
  if (amount) {
    let parsedAmount = parseFloat(amount);
    if (parsedAmount >= 0) {
      return "success.main";
    } else {
      return "error.main";
    }
  } else {
    return "text.primary";
  }
}

export function currencyToCurrencySymbol(currency: Currency): string {
  switch (currency) {
    case Currency.USD:
      return "$";
    case Currency.EUR:
      return "€";
    case Currency.INVALID:
      return "";
  }
}

export function formatFiatCurrency(
  currency: Currency,
  amount: string | undefined,
  local: string
): string {
  if (amount) {
    let splitStr = amount?.split("-");
    let isNegative = false;
    let number = Number(0);
    let numberDecimalSplit: RegExpMatchArray | null = null;
    let leadingZeros = Number(0);

    if (splitStr.length === 2) {
      isNegative = true;
      number = parseFloat(splitStr[1]);
      numberDecimalSplit = splitStr[1].match(/^(0\.0*)/);

      if (numberDecimalSplit && numberDecimalSplit[1].length) {
        leadingZeros = numberDecimalSplit[1].length - 2;
      }
    }

    if (splitStr.length === 1) {
      number = parseFloat(splitStr[0]);
      numberDecimalSplit = splitStr[0].match(/^(0\.0*)/);
      if (numberDecimalSplit && numberDecimalSplit[1].length) {
        leadingZeros = numberDecimalSplit[1].length - 2;
      }
    }
    if (number >= 1) {
      // if greater than $1 we show two decimal places
      const formattedNumber = new Intl.NumberFormat(local, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(number.toPrecision(2)));

      return isNegative ? `-${formattedNumber}` : formattedNumber;
    }

    // if number has more than 4 leading zeros we have no choice but to round
    else if (leadingZeros >= 4) {
      const formattedNumber = new Intl.NumberFormat(local, {
        style: "currency",
        currency: currency,
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 3,
      }).format(Number(0.0001));
      return isNegative ? `-${formattedNumber}` : formattedNumber;
    } else {
      console.log(number);
      // if number is less than 1 we return 3 significant digits
      const formattedNumber = new Intl.NumberFormat(local, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
      }).format(Number(number.toPrecision(4)));
      return isNegative ? `-${formattedNumber}` : formattedNumber;
    }
  } else {
    return " 0";
  }
}

export function scaleSizeToPrecision(size: string | undefined): string {
  if (size) {
    let sizeNum = Number(size);
    if (sizeNum >= 1e5) {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        notation: "compact",
        compactDisplay: "short",
      }).format(parseFloat(size));
    } else {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 3,
      }).format(parseFloat(size));
    }
  } else {
    return " 0";
  }
}

export function scaleToPercentage(number: number, decimals: number): string {
  const numToPct = number * 100;
  return numToPct.toFixed(decimals);
}

export function clampPrecision(
  tokenPositions: TokenPosition[]
): TokenPosition[] {
  return tokenPositions.map((pos) => {
    let sanitizedPos = {
      token: pos.token,
      holdingPercent: pos.holdingPercent,
      openPosition: undefined,
      closedPositions: undefined,
      fees: pos.fees,
    } as TokenPosition;

    if (pos.openPosition) {
      sanitizedPos.openPosition = {
        __typename: pos.__typename,
        size: parseFloat(pos.openPosition.size).toFixed(12),
        totalSize: parseFloat(pos.openPosition.totalSize).toFixed(12),
        totalCost: parseFloat(pos.openPosition.totalCost).toFixed(12),
        averagePriceOpen: parseFloat(pos.openPosition.averagePriceOpen).toFixed(
          12
        ),
        capitalInvested: parseFloat(pos.openPosition.capitalInvested).toFixed(
          12
        ),
        averagePriceTotal: parseFloat(
          pos.openPosition.averagePriceTotal
        ).toFixed(12),
        breakevenPrice: parseFloat(pos.openPosition.breakevenPrice).toFixed(12),
        currentPrice: parseFloat(pos.openPosition.currentPrice).toFixed(12),
        costOfOpen: parseFloat(pos.openPosition.costOfOpen).toFixed(12),
        valueOfOpen: parseFloat(pos.openPosition.valueOfOpen).toFixed(12),
        unrealizedPnl: parseFloat(pos.openPosition.unrealizedPnl).toFixed(12),
        unrealizedPnlAbs: parseFloat(pos.openPosition.unrealizedPnlAbs).toFixed(
          12
        ),
        realizedPnl: parseFloat(pos.openPosition.realizedPnl).toFixed(12),
        realizedPnlAbs: parseFloat(pos.openPosition.realizedPnlAbs).toFixed(12),
        changes: pos.openPosition.changes,
        inaccurate: pos.openPosition.inaccurate,
        opened: pos.openPosition.opened,
        closed: pos.openPosition.closed,
        fees: pos.openPosition.fees,
      };
    }
    if (pos.closedPositions && pos.closedPositions.length > 0) {
      sanitizedPos.closedPositions = pos.closedPositions;
    }
    return sanitizedPos;
  });
}

export const positionDuration = (ts: number): String => {
  const dateNow = new Date();
  const changeDate = new Date(ts * 1000);

  const hours = Math.abs(dateNow.getTime() - changeDate.getTime()) / 36e5;
  if (hours < 1) {
    return `${1}h`;
  } else if (hours <= 24) {
    return `${Math.round(hours)}h`;
  } else {
    const days_past = Math.floor(hours / 24);
    const hours_past = Math.round(Math.floor(hours % 24));
    return `${days_past}d${hours_past}h`;
  }
};
