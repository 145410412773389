export enum PositionChangeType {
  BUY = "BUY",
  SELL = "SELL",
}

export enum CandleResolution {
  ONE_HOUR = "ONE_HOUR",
}

export enum Blockchain {
  Arbitrum = "ARBITRUM",
  Ethereum = "ETHEREUM",
  Solana = "SOLANA",
}

export interface PriceData {
  time: string;
  open: number;
  high: number;
  low: number;
  close: number;
}

export interface CandleData {
  candles: [string, string, string, string, string, string, string][];
}

export interface PositionChange {
  __typename: string;
  changeType: string;
  changeAmount: string;
  realizedPnl: string;
  realizedPnlAbs: string;
  averagePriceAtSale: any;
  buyOrSellPrice?: string;
  txId: string;
  fee: Price;
  ts: number;
}

export interface Position {
  __typename: string;
  size: string;
  totalSize: string;
  totalCost: string;
  capitalInvested: string;
  averagePriceOpen: string;
  averagePriceTotal: string;
  breakevenPrice: string;
  currentPrice: string;
  costOfOpen: string;
  valueOfOpen: string;
  unrealizedPnl: string;
  unrealizedPnlAbs: string;
  realizedPnl: string;
  realizedPnlAbs: string;
  changes: PositionChange[];
  inaccurate: Boolean;
  opened: number;
  closed?: any;
  fees: Price;
}

type Nullable<T> = T | null;

export interface TokenPositionData {
  tokenPosition: TokenPosition;
}
export interface TokenPosition {
  __typename: string;
  token: Token;
  openPosition?: Nullable<Position>;
  closedPositions?: Position[];
  holdingPercent: number;
  fees: Price;
}

export interface Announcements {
  announcements: Announcement[];
}
export interface Announcement {
  __typename: string;
  id: string;
  title: string;
  body: string;
}

export interface FooterAnnouncement {
  text: string;
  url: string;
}

export interface Address {
  address: {
    __typename?: string;
    positions: WalletPositions;
  };
}

export interface WalletPositions {
  __typename: string;
  totalValue: string;
  totalCost: string;
  totalFees: Price;
  positions: TokenPosition[];
}

export interface Token {
  __typename: string;
  name: string;
  symbol?: string;
  decimals: number;
  addr: string;
  logoUri?: Nullable<string>;

  // revert to string
  price?: Nullable<string>;
  priceDayAgo?: Nullable<string>;
}

export enum Currency {
  INVALID = "INVALID",
  USD = "USD",
  EUR = "EUR",
}

export interface Price {
  __typename?: string;
  tokenMeta: Token;
  tokenAmount?: string;
  fiat?: string;
  fiatCurrency: Currency;
  timestamp?: Number;
}
