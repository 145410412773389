export function formatNumber(
  value: string,
  locale: string | null = null,
  decimalRange: [number, number]
): string {
  const defaultDecimalSeparator = ".";
  const [minDecimalDigits, maxDecimalDigits] = decimalRange;

  const regex = new RegExp(`[${defaultDecimalSeparator},]`, "g");
  const numberString = value.toString().replace(regex, defaultDecimalSeparator);

  let [integerString, decimalString = ""] = numberString.split(
    defaultDecimalSeparator
  );

  integerString = integerString.replace(/^0+/, "");
  decimalString = decimalString.replace(/0+$/, "");

  if (decimalString) {
    const decimals = decimalString.padEnd(minDecimalDigits, "0");

    let nonZeroCount = 0;
    let firstNonZeroIndex = null;
    let nonZeroDecimalCount = 0;
    let firstNonZeroDecimalIndex = null;

    for (let i = 0; i < decimals.length; i++) {
      const char = decimals[i];

      if (char !== "0") {
        nonZeroCount++;

        if (firstNonZeroIndex === null) {
          firstNonZeroIndex = i;
        }
      }

      if (nonZeroCount > 0) {
        nonZeroDecimalCount++;

        if (firstNonZeroDecimalIndex === null) {
          firstNonZeroDecimalIndex = i;
        }
      }

      if (i >= maxDecimalDigits - 1) {
        break;
      }
    }
    if (nonZeroDecimalCount === 0 && integerString === "0") {
      decimalString = "";
      return integerString + decimalString;
    }
  }

  let result = integerString;
  if (locale) {
    if (integerString.length >= 5) {
      return Number(integerString).toLocaleString(locale, {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        notation: "compact",
      });
    }
    if (decimalString) {
      result += defaultDecimalSeparator + decimalString;

      return Number(result).toLocaleString(locale, {
        useGrouping: true,
        minimumFractionDigits: minDecimalDigits,
        maximumFractionDigits: maxDecimalDigits,
        notation: "compact",
      });
    }

    return Number(result).toLocaleString(locale, {
      useGrouping: true,
      notation: "compact",
      compactDisplay: "short",
    });
  } else {
    const formatter = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: minDecimalDigits,
      maximumFractionDigits: maxDecimalDigits,
      useGrouping: true,
      notation: "compact",
      compactDisplay: "short",
    });
    if (decimalString) {
      result += defaultDecimalSeparator + decimalString;
      return formatter.format(Number(result));
    }
    return formatter.format(Number(result));
  }
}

export function fiatFormat(
  value: string,
  currency: string | null,
  local: string
): string {
  const formattedValue = formatNumber(value, local, [2, 8]);

  if (!currency) {
    return formattedValue;
  }

  switch (currency) {
    case "EUR":
      return `${formattedValue} €`;
    case "USD":
      return `$${formattedValue}`;
    default:
      return formattedValue;
  }
}

export function cryptoFormat(
  value: string,
  currency: string | undefined,
  local: string,
  decimalRange: [number, number] = [2, 8]
): string {
  const formattedValue = formatNumber(value, local, decimalRange);
  if (!currency) {
    return formattedValue;
  } else {
    return `${formattedValue} ${currency.toUpperCase()}`;
  }
}
