import { keyframes } from "@emotion/react";

export const rotate = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
`;

export const scrollTokens = keyframes`
from {
    transform: translateX(0)
} 

to {
    transform: translateX(calc(-50%))
}
`;

export const ticker = keyframes`
0% {
    transform: translateX(0%);
}

100% {
  transform: translateX(-100%);
}`;
