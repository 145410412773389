/** @jsxImportSource @emotion/react */

import { Grid } from "@mui/material";
import { Position, Token } from "../gql/types";
import { LeftBanner } from "./PositionPage/LeftBanner";
import { PositionBadge } from "./PositionPage/PositionBadge";

interface PositionPageProps {
  openPosition: Position;
  token: Token;
}

export const PositionPage = ({
  openPosition,
  token,
}: PositionPageProps): JSX.Element => {
  return (
    <Grid
      container
      css={{
        height: "inherit",
      }}
    >
      <Grid
        item
        md={7}
        css={{
          display: "flex",
        }}
        alignItems={"center"}
        justifyContent="center"
      >
        <LeftBanner openPosition={openPosition} token={token} />
      </Grid>
      <Grid
        item
        md={5}
        css={{
          display: "flex",
        }}
        alignItems={"center"}
        justifyContent="center"
      >
        <PositionBadge openPosition={openPosition} token={token} />
      </Grid>
    </Grid>
  );
};
