/** @jsxImportSource @emotion/react */
import ultimate from "../images/ultimate.svg";
import { rotate } from "../styles/keyframes";

export const LoadingSpinner = (): JSX.Element => {
  return (
    <div
      css={{
        position: "absolute",
        top: "45%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: "50vh",
        width: "50vh",
        border: "none",
        background: "transparent",
        textAlign: "center",
        textTransform: "uppercase",
        "&:before": {
          height: "50%",
          width: "50%",
          margin: "auto",
          animation: `${rotate} 3s linear infinite`,
          content: '""',
          position: "absolute",
          left: "calc(25%)",
          borderRadius: "50%",
          borderTop: `6px solid #855df9`,
          borderRight: `3px solid #855df9`,
        },
      }}
    >
      <img
        css={{
          position: "relative",
          top: "25%",
          transform: "translateY(-50%)",
          width: "auto",
          height: "33%",
        }}
        src={ultimate}
        alt={"ultimate finance"}
      />
    </div>
  );
};
