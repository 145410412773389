/** @jsxImportSource @emotion/react */

import { ThemeProvider } from "@emotion/react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Position, Token } from "../../gql/types";
import {
  darkThemeLazarGrotesk,
  lightThemeLazarGrotesk,
} from "../../styles/theme";
import { scaleToPercentage } from "../../utils";
import ultimateGrotesk from "../../images/ultimateGrotesk.svg";
import QR from "../../images/QR.svg";

interface leftBannerProps {
  openPosition: Position;
  token: Token;
}

export const LeftBanner = ({
  openPosition,
  token,
}: leftBannerProps): JSX.Element => {
  const theme = useTheme();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  return (
    <div
      css={{
        paddingLeft: "78px",
      }}
    >
      <img
        src={ultimateGrotesk}
        alt={"Ultimate Money"}
        css={{
          position: "absolute",
          left: "80px",
          maxWidth: "7.5rem",
          minWidth: "7.5rem",
          minHeight: "auto",
          objectFit: "contain",
        }}
      />
      <ThemeProvider
        theme={prefersDarkMode ? darkThemeLazarGrotesk : lightThemeLazarGrotesk}
      >
        <Typography
          variant="h1"
          css={{
            marginTop: "2.5rem",
          }}
        >
          I'M{" "}
          <span
            css={{
              color:
                parseFloat(openPosition.unrealizedPnl) >= 0
                  ? theme.palette.success.main
                  : theme.palette.error.main,
            }}
          >
            {parseFloat(openPosition.unrealizedPnl) >= 0 ? "UP" : "DOWN"}{" "}
            {scaleToPercentage(Number(openPosition.unrealizedPnl), 2)}%
          </span>
        </Typography>

        <Typography variant="h1">
          TRADING ${token.symbol?.toUpperCase()}
        </Typography>
      </ThemeProvider>

      <div
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "1.5rem",
        }}
      >
        <img
          src={QR}
          alt={"Ultimate Money"}
          css={{
            maxWidth: "5rem",
            minWidth: "5rem",
            minHeight: "auto",
            objectFit: "contain",
          }}
        />
        <div
          css={{
            marginLeft: "1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="label1"
            css={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            Tracked via Ultimate
          </Typography>
          <Typography
            variant="body2"
            css={{
              color: theme.palette.grey[100],
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            The Wallet for Traders
          </Typography>
        </div>
      </div>
    </div>
  );
};
