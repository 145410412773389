import { gql } from "@apollo/client";

export const GET_POSITION = gql(`
query getTokenPosition($uri: String) {
  tokenPosition(uri: $uri) {
    token {
      name
      addr
      logoUri
      symbol
      decimals
      priceDayAgo
      price
    }
    openPosition {
      size
      capitalInvested
      averagePriceOpen
      averagePriceTotal
      breakevenPrice
      currentPrice
      costOfOpen
      valueOfOpen
      unrealizedPnl
      unrealizedPnlAbs
      realizedPnl
      realizedPnlAbs
      changes {
        changeType
        changeAmount
        realizedPnl
        realizedPnlAbs
        averagePriceAtSale
        buyOrSellPrice
        txId
        ts
        fee {
          tokenMeta {
            name
            addr
            logoUri
            symbol
          }
          fiat
          tokenAmount
        }
      }
      inaccurate
      opened
      closed
      fees {
        tokenMeta {
          name
          addr
          logoUri
          symbol
        }
        fiat
        fiatCurrency
        tokenAmount
      }
    }
  }
}

`);
